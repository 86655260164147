import { defineStore } from 'pinia';
import API from '@/shared/helpers/API.js';
import {useUserStore} from "@/console-new/stores/user.store";

export const usePrintersStore = defineStore('printers', {
	state: () => ({
		businessPrinters: null,
	}),
	actions: {
		getBusinessPrinters(callback = null) {
			if(this.businessPrinters != null) { callback && callback(this.businessPrinters); return; }

			let userStore = useUserStore();
			if(!userStore.currBusinessId) { return; }
			API.init().getPrinters(userStore.currBusinessId, response => {
				if (response && response.result === "OK") {
					if(response.printers && response.printers.length > 0) {
						this.normalizePrinters(response.printers, response.categories_excl, response.users_excl);
						this.businessPrinters = response.printers;
					} else {
						this.businessPrinters = [];
					}
					callback && callback(this.businessPrinters);
				}
			});
		},
		normalizePrinters(printers, categoriesExcl, usersExcl) {

			let categoriesExcludedMap = {};
			for(let i = 0; i < categoriesExcl.length; i++) {
				if(categoriesExcl[i].print_small === "1") {
					continue;
				}
				if(!categoriesExcludedMap.hasOwnProperty(categoriesExcl[i].id)) {
					categoriesExcludedMap[categoriesExcl[i].id] = [];
				}
				categoriesExcludedMap[categoriesExcl[i].id].push(categoriesExcl[i].menu_category_id);
			}
			let categoriesPrintSmallMap = {};
			for(let i = 0; i < categoriesExcl.length; i++) {
				if(categoriesExcl[i].print_small !== "1") {
					continue;
				}
				if(!categoriesPrintSmallMap.hasOwnProperty(categoriesExcl[i].id)) {
					categoriesPrintSmallMap[categoriesExcl[i].id] = [];
				}
				categoriesPrintSmallMap[categoriesExcl[i].id].push(categoriesExcl[i].menu_category_id);
			}
			let usersMap = {};
			for(let i = 0; i < usersExcl.length; i++) {
				if(!usersMap.hasOwnProperty(usersExcl[i].id)) {
					usersMap[usersExcl[i].id] = [];
				}
				usersMap[usersExcl[i].id].push(usersExcl[i].user_id);
			}

			for(let i = 0; i < printers.length; i++) {
				printers[i].id = Number(printers[i].id);
				printers[i].total_prints = Number(printers[i].total_prints);
				printers[i].server_version = Number(printers[i].server_version);
				printers[i].active = printers[i].active === '1';
				printers[i].print_order_total = printers[i].print_order_total === '1';
				printers[i].name_break_line = printers[i].name_break_line === '1';
				printers[i].product_double_height = printers[i].product_double_height === '1';
				printers[i].white_space_header = printers[i].white_space_header === '1';
				printers[i].header_double_size = printers[i].header_double_size === '1';
				printers[i].print_redeem_qr = printers[i].print_redeem_qr === '1';
				printers[i].print_order_qr = printers[i].print_order_qr === '1';
				printers[i].print_exploded_items = printers[i].print_exploded_items === '1';
				printers[i].edit_order_summary = printers[i].edit_order_summary === '1';
				printers[i].mode_counter_business = printers[i].mode_counter_business === '1';
				printers[i].mode_qr_business = printers[i].mode_qr_business === '1';
				printers[i].mode_delivery_business = printers[i].mode_delivery_business === '1';
				printers[i].mode_takeaway_business = printers[i].mode_takeaway_business === '1';
				printers[i].mode_qr_customer = printers[i].mode_qr_customer === '1';
				printers[i].mode_delivery_customer = printers[i].mode_delivery_customer === '1';
				printers[i].mode_takeaway_customer = printers[i].mode_takeaway_customer === '1';
				printers[i].can_reprint_orders = printers[i].can_reprint_orders === '1';
				printers[i].can_print_prebill = printers[i].can_print_prebill === '1';
				printers[i].fiscal_print = printers[i].fiscal_print === '1';
				printers[i].play_audio = printers[i].play_audio === '1';
				printers[i].categoriesExcl = categoriesExcludedMap[printers[i].id] || [];
				printers[i].categoriesSmall = categoriesPrintSmallMap[printers[i].id] || [];
				printers[i].usersExcl = usersMap[printers[i].id] || [];
			}
		},
		updateCategoryPrinters(categoryId, callback = null) {
			let userStore = useUserStore();
			if(!this.businessPrinters) { return; }

			let categoriesPrinter = [];
			for(let i = 0; i < this.businessPrinters.length; i++) {
				let active = this.businessPrinters[i].categoriesExcl.find(cat => Number(cat) === Number(categoryId));
				categoriesPrinter.push({
					id: this.businessPrinters[i].id,
					active: !active
				});
			}

			API.init().printerChangeCategory(userStore.currBusinessId, categoryId, JSON.stringify(categoriesPrinter), r => {
				if(r && r.result === "OK") {
					this.businessPrinters = null;
					this.getBusinessPrinters(function () {
						callback && callback(true);
					});
				} else {
					callback && callback(false);
				}
			});
		},
		updatePrinterExcludedCategories(id, categories, categoriesSmall, users, callback = null) {
			let userStore = useUserStore();
			API.init().printerUpdateExcludedCategories(userStore.currBusinessId, id, JSON.stringify(categories), JSON.stringify(categoriesSmall), JSON.stringify(users), r => {
				if (callback) { callback(r && r.result === "OK"); }
			});
		},
		testPrint(printerId, callback = null) {
			let userStore = useUserStore();
			API.init().printTest(userStore.currBusinessId, printerId, response=> {
				if (callback) { callback(response && response.result === "OK"); }
			});
		},
		savePrinter(printerId, callback = null) {
			let userStore = useUserStore();
			let printer = this.businessPrinters.find(itemToFind => {
				return itemToFind.id === printerId
			});
			if (printer) {
				if(printer.printer_type !== "rt"){
					API.init().printerUpdate(userStore.currBusinessId, printer.id, printer.name, printer.active, printer.print_order_total, printer.name_break_line, printer.product_double_height, printer.white_space_header, printer.header_double_size, printer.print_redeem_qr, printer.print_order_qr, printer.print_exploded_items, printer.edit_order_summary, printer.can_reprint_orders, printer.can_print_prebill, printer.play_audio, printer.fiscal_print, printer.mode_counter_business, printer.mode_qr_business, printer.mode_delivery_business, printer.mode_takeaway_business, printer.mode_qr_customer, printer.mode_delivery_customer, printer.mode_takeaway_customer, response => {
						if (callback) { callback(response && response.result === "OK"); }
					});
				} else {
					API.init().printerUpdate(userStore.currBusinessId, printer.id, printer.name, printer.active, 0, 0, 0, 0, 0, 0, 0, 0, printer.fiscal_print, response => {
						if (callback) { callback(response && response.result === "OK"); }
					});
				}
			}
		},
		deletePrinter(printerId, callback = null) {
			let userStore = useUserStore();
			let printer = this.businessPrinters.find(itemToFind => {
				return itemToFind.id === printerId
			});
			if (printer) {
				API.init().deletePrinter(userStore.currBusinessId, printer.id, response => {
					if (callback) { callback(response && response.result === "OK"); }
				});
			}
		},
	}
});
